.mi-contact {
  &-area {
    .mi-sectiontitle {
      margin-bottom: 30px;
    }

    h2 {
      text-transform: uppercase;
      font-size: 22px;
      margin-bottom: 0;
    }
  }

  &-info {
    display: flex;
    width: calc(100%);
    flex-wrap: nowrap;
  }

  &-infoblock {
    margin: 15px 5px 30px 5px;
    background: rgba(lighten($color-bg-body, 5), 1);
    padding: 30px;
    border-radius: 7px;
    border-top: 5px solid #2e344e;

    &:hover {
      border-top-color: #037fff;
    }

    &.address {
      font-size: small;
      padding-right: 10px;
      padding-left: 10px;
    }

    &-icon {
      display: block;
      height: 70px;
      width: 70px;
      flex: 0 0 70px;
      max-width: 70px;
      border: 1px solid $color-border;
      text-align: center;
      line-height: 66px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      text-align: center;
      clear: both;

      svg {
        height: 30px;
        width: 30px;
      }
    }

    &-content {
      text-align: center;

      h6 {
        margin-top: -5px;
      }

      p {
        margin-bottom: 0;

        &.note {
          font-size: small;
        }

        a {
          transition: $transition;

          &:hover {
            color: $color-theme;
          }
        }
      }
    }
  }

  @media #{$layout-tablet} {
    &-info {
      margin-top: 50px;
    }
  }

  @media #{$layout-mobile-lg} {
    &-info {
      margin-top: 50px;
    }
  }

  @media #{$layout-mobile-sm} {
    &-area {
      h2 {
        width: 100%;
        text-align: center;
      }
    }

    &-info {
      width: 100%;
      margin-top: 0px;
      flex-wrap: wrap;
    }

    &-infoblock {
      padding: 20px;
      flex-wrap: wrap;
      margin-left: 30px;

      &-icon {
        height: 55px;
        width: 55px;
        flex: 0 0 55px;
        max-width: 55px;
        line-height: 51px;
      }

      &-content {
        margin-top: 20px;
        flex: 0 0 100%;
      }
    }
  }
}
